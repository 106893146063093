import { Plugin } from '@nuxt/types';
import { AxiosBackendType } from '../types/backend/axios';
import { Cookies } from '..//types/enum';

const http: Plugin = (
  { $axios, $fire, i18n, $config: { backendBaseUrl } }: any,
  inject,
) => {
  const baseURL = backendBaseUrl;

  // let requestsInProgress = 0;
  let firstRequest = true;

  const axiosInstance = $axios.create({
    baseURL,
    timeout: 90000,
  });

  async function getToken() {
    if ($fire.auth.currentUser) {
      const idToken = await $fire.auth.currentUser.getIdToken(firstRequest);
      firstRequest = false;
      return idToken;
    }
    return Promise.resolve(null);
  }

  axiosInstance.interceptors.request.use(
    async (config) => {
      if (process.server) {
        console.log(
          `[http] ${config.method?.toUpperCase()} ${config.baseURL}${
            config.url
          }`,
        );
      }
      const returnConfig = config;
      // requestsInProgress += 1;
      if (process.client) {
        // can influnce ssr?
        if (returnConfig.headers) {
          if (i18n?.locale) {
            returnConfig.headers['x-custom-lang'] = i18n.locale;
          } else {
            returnConfig.headers['x-custom-lang'] = 'pl';
          }
        }
      }

      const idToken = await getToken();
      if (returnConfig.headers && idToken) {
        returnConfig.headers.Authorization = `Bearer ${idToken}`;
        if (process.client) {
          const profile = localStorage.getItem('profile');
          if (profile) {
            returnConfig.headers['x-selected-profile'] = JSON.parse(profile).id;
          }
        }
      }
      return returnConfig;
    },
    (error: {}) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (response: {}) => response,
    (error: {
      response: {
        status: number;
      };
      message: string;
    }) => {
      // eslint-disable-next-line no-console
      console.log(error);
      if (error.response && error.response.status === 401) {
        // tbd
      } else if (error.message === 'Network Error') {
        if (navigator.onLine) {
          console.log('Network error while online');
        } else {
          console.log('Network error while offline');
        }
      } else if (error.message !== 'Operation canceled by the user.') {
        // tbd
      }
      return Promise.reject(error);
    },
  );

  // axiosInstance.onRequest(async (config) => {
  //   const idToken = await getToken();
  //   if (config && idToken) {
  //     axiosInstance.setHeader(idToken, 'Bearer');
  //     const profile = localStorage.getItem('profile');
  //     if (profile) {
  //       axiosInstance.setHeader('x-selected-profile', JSON.parse(profile).id);
  //     }
  //     const locale = localStorage.getItem(Cookies.LOCALE);
  //     if (locale) {
  //       axiosInstance.setHeader('x-custom-lang', locale);
  //     } else {
  //       axiosInstance.setHeader('x-custom-lang', 'pl');
  //     }
  //   }
  // })

  // axiosInstance.onResponseError((error) => {
  //   // eslint-disable-next-line no-console
  //   console.log(error);
  //   if (error.response && error.response.status === 401) {
  //     // tbd
  //   } else if (error.message === 'Network Error') {
  //     if (navigator.onLine) {
  //       // tbd
  //     } else {
  //       // tbd
  //     }
  //   } else if (error.message !== 'Operation canceled by the user.') {
  //     // tbd
  //   }
  // });

  // window.addEventListener('beforeunload', (event) => {
  //   if (requestsInProgress) {
  //     // eslint-disable-next-line no-param-reassign
  //     event.returnValue = 'Masz niezapisane zmiany czy na pewno chcesz zamknąć?';
  //   }
  // });

  inject('http', axiosInstance);
};

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosBackendType;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $http: AxiosBackendType;
  }
  interface Context {
    $http: AxiosBackendType;
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $http: AxiosBackendType;
  }
}

export default http;
